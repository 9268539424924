import React from "react";
import "./ContactUs.css";
import Header from "./Header";
import Footer from "./Footer";

const ContactUs = () => {
  return (
    <>
      <Header />
      <div className="ContactForm">
        <div className="container">
          <div className="row">
            <div className="col-12 ">
              <div className="contactForm">
                <div className="contactUs">
                  <h2 classNmae="topic">Contáctanos</h2>
                  <div>
                    <h2>Terminos y Condiciones</h2>

                    <p>
                    Al utilizar nuestro sitio web o aplicación, usted acepta cumplir y 
                    estar sujeto a los siguientes Términos y condiciones. Revise los 
                    términos detenidamente antes de utilizar nuestros servicios.
                    </p>

                    <h3>Pago y Facturación</h3>
                    <p>
                    Todos los pagos se procesan de forma segura y no almacenamos su 
                    información de pago.
                    </p>

                    <p>
                    Lea nuestros Términos y condiciones completos para comprender 
                    de manera integral sus derechos y responsabilidades.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactUs;
