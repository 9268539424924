// components/WhatsAppButton.js
import React from "react";
import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";

const WhatsAppButton = () => {
  return (
    <WhatsAppWidget
      phoneNo="573128044005" // Reemplaza con tu número de WhatsApp
      position="right"
      widgetWidth="300px"
      widgetWidthMobile="260px"
      autoOpen={false}
      messageBox={true}
      messageBoxTxt="Hola, tengo dudas sobre.."
      iconSize="50"
      iconColor="white"
      iconBgColor="green"
      headerIcon="https://i.imgur.com/aZMBPS0.jpeg"
      headerIconColor="pink"
      headerTxtColor="black"
      headerBgColor="white"
      headerTitle="Callcenter"
      headerCaption="En línea"
      bodyBgColor="#bbb"
      chatPersonName="Atención al Cliente"
      chatMessage={<>Hola👋 <br /><br /> Tienes algunas dudas?</>}
      footerBgColor="#999"
      placeholder="Escribe tu mensaje..."
      btnBgColor="yellow"
      btnTxt="Iniciar Chat"
      btnTxtColor="black"
    />
  );
};

export default WhatsAppButton;
