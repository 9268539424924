import React from "react";
import "./ContactUs.css";
import Header from "./Header";
import Footer from "./Footer";

const ContactUs = () => {
  return (
    <>
      <Header />
      <div className="ContactForm">
        <div className="container">
          <div className="row">
            <div className="col-12 ">
              <div className="contactForm">
                <div className="contactUs">
                  <h2 classNmae="topic">Contáctanos</h2>
                  <div>
                    <h2>Política de cancelación y reembolso</h2>

                    <h3>Cancelación</h3>
                    <p>
                    Puede cancelar su pedido dentro de las 24 horas posteriores 
                    a su realización.
                    </p>

                    <h3>Proceso de reembolso</h3>
                    <p>
                      La empresa no realiza reembolso de dinero en efectivo o 
                      transferencia, a menos que se llegue a un acuerdo entre las partes.
                    </p>

                    <p>
                    Para obtener más información, visite nuestra página de Cancelación 
                    y Reembolso.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactUs;
