import React from "react";
import "./ContactUs.css";
import Header from "./Header";
import Footer from "./Footer";

const ContactUs = () => {
  return (
    <>
      <Header />
      <div className="ContactForm">
        <div className="container">
          <div className="row">
            <div className="col-12 ">
              <div className="contactForm">
                <div className="contactUs">
                  <h2 classNmae="topic">Contáctanos</h2>
                  <div>
                    <h2>Envio y Entrega</h2>

                    <h3>Métodos de envío</h3>
                    <p>Ofrecemos opciones de envío estándar y acelerado.</p>

                    <h3>Plazos de entrega</h3>
                    <p>
                    Los tiempos de entrega estimados varían según su ubicación 
                    y el método de envío elegido.
                    </p>

                    <p>
                    Obtenga más información sobre nuestro proceso de envío y 
                    entrega en la página dedicada.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactUs;
