import React from "react";
import "./ContactUs.css";
import Header from "./Header";
import Footer from "./Footer";

const ContactUs = () => {
  return (
    <>
      <Header />
      <div className="ContactForm">
        <div className="container">
          <div className="row">
            <div className="col-12 ">
              <div className="contactForm">
                <div className="contactUs">
                  <h2 classNmae="topic">Contáctanos</h2>

                  <h2>Nosotros</h2>
                  <p>
                  Bienvenido a <span>MaJo Tienda de Regalos</span>, donde su experiencia 
                  de compra es nuestra principal prioridad. Establecidos, nos hemos 
                  dedicado a brindar a nuestros clientes productos de alta calidad y 
                  un servicio excepcional.
                  </p>

                  <h3>Nuestra Historia</h3>
                  <p>
                  <span>MaJo Tienda de Regalos</span> se fundó con pasión. Nuestro 
                  viaje comenzó cuando imaginamos la creación de una plataforma que 
                  no solo ofreciera una amplia gama de productos premium sino que 
                  también fomentara una comunidad de personas con ideas afines que 
                  aprecian.
                  </p>

                  <h3>Lo que nos diferencia</h3>
                  <ul>
                    <li>
                    <strong>Garantía de calidad:</strong> Seleccionamos cuidadosamente 
                    cada producto de nuestra colección para garantizar los más altos 
                    estándares de calidad. Su satisfacción es nuestra garantía.
                    </li>
                    <li>
                    <strong>Enfoque centrado en el cliente:</strong> Nuestros clientes 
                    están en el centro de todo lo que hacemos. Estamos comprometidos 
                    a brindar un excelente servicio al cliente y una experiencia de 
                    compra perfecta.
                    </li>
                    <li>
                    <strong>Innovación:</strong> Nos mantenemos a la vanguardia
                    de tendencias para traerte los productos más recientes y más 
                    innovadores.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactUs;
