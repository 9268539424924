import React from "react";
import "./ContactUs.css";
import Header from "./Header";
import Footer from "./Footer";

const ContactUs = () => {
  return (
    <>
      <Header />
      <div className="ContactForm">
        <div className="container">
          <div className="row">
            <div className="col-12 ">
              <div className="contactForm">
                <div className="contactUs">
                  <h2 classNmae="topic">Contáctanos</h2>
                  <div>
                    <h2>Política de Privacidad</h2>

                    <p>
                    Bienvenido a MajoTiendas
                    Esta Política de Privacidad describe cómo recopilamos, 
                    usamos y manejamos su información personal cuando utiliza 
                    nuestros servicios. Tómese un momento para leer esta 
                    política y comprender cómo tratamos sus datos.
                    </p>

                    <h3>Información que recopilamos</h3>
                    <p>
                    Recopilamos información personal, como su nombre, 
                    datos de contacto y dirección cuando crea una cuenta o 
                    realiza una compra. Además, utilizamos cookies y tecnologías 
                    similares para mejorar su experiencia de navegación.
                    </p>

                    <h3>Cómo utilizamos su información</h3>
                    <p>
                    Su información se utiliza para procesar pedidos, mejorar 
                    nuestros servicios y comunicarnos con usted sobre promociones 
                    o actualizaciones.
                    </p>

                    <h3>Medidas de seguridad</h3>
                    <p>
                    Empleamos medidas de seguridad estándar de la industria para 
                    proteger su información personal del acceso no autorizado.
                    </p>

                    <p>
                    Para obtener más detalles, lea nuestra Política de privacidad completa.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactUs;
