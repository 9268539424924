import React from "react";
import "./ContactUs.css";
import Header from "./Header";
import Footer from "./Footer";

const ContactUs = () => {
  return (
    <>
      <Header />
      <div className="ContactForm">
        <div className="container">
          <div className="row">
            <div className="col-12 ">
              <div className="contactForm">
                <div className="contactUs">
                  <h2 classNmae="topic">Contáctanos</h2>
                  <h3>Atención al cliente</h3>
                  <p>Nuestro equipo de atención al cliente está disponible 24/7.</p>
                  <p>
                    Comuníquese con nosotros por correo electrónico a{" "}
                    <a href="mailto: majogiftshop@gmail.com">
                      {" "}
                      majogiftshop@gmail.com
                    </a>{" "}
                    o a través de nuestro Formulario de Contacto.
                  </p>

                  <h3>Horario de Oficina</h3>
                  <p>
                  Nuestro servicio de atención al cliente está disponible de 
                  lunes a viernes, de 9:00 a. m. a 5:00 p. m.
                  </p>

                  <p>
                  Visite nuestra página Contáctenos para conocer más formas 
                  de ponerse en contacto. Cualquier consulta será respondida 
                  dentro de las 24 horas. Gracias por tu paciencia. Intentaremos 
                  solucionar tu problema lo antes posible.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactUs;
