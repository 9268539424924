import { Box } from "@mui/system";
import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <Box className="footer">
      <Box>
        <img src="logomajo.svg" alt="QKart-icon"></img>
      </Box>
      <Box className="footer-social">
        <Link to="/contact-us" className="footer-link">
          Contáctanos
        </Link>
        <Link to="/shipping-and-delivery" className="footer-link">
        Envío y entrega
        </Link>
        <Link to="/cancellation-and-refund" className="footer-link">
        Cancelación
        </Link>
        <Link to="/terms-and-condition" className="footer-link">
          Terminos y Condiciones
        </Link>
        <Link to="/privacy-policy" className="footer-link">
          Política de Privacidad
        </Link>
        <Link to="/about-us" className="footer-link">
          Nosotros
        </Link>
      </Box>
      <p className="footer-text">
      Majo Tienda de Reaglos es su solución integral para comprar los últimos artículos de tendencia con la entrega más rápida hasta su puerta.
      </p>
    </Box>
  );
};

export default Footer;
